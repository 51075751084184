/* Custom CSS for pricing Page  */


.pricing-container:hover {
    transition-delay: 0s;
    transition: 0.3s all;
    background: linear-gradient(20deg, #2A88FC, #59DCE7);
    color: white;
}

.pricing-container:hover .offer,
.pricing-container:hover .planInfo {
    transition: 0.3s all;
    color: white;
    transition-delay: 0s;
}
.pricing-container:hover .card-head{
    color: white;
    transition-delay: 0s;
}

.pricing-container:hover .pricing-container-btn {
    background: linear-gradient(20deg, #2A88FC, #59DCE7);
}