@import url("https://fonts.googleapis.com/css2?family=Anton&family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  /* font-family: "Barlow Condensed", sans-serif; */
  font-family: "Lato", sans-serif;
  background: #f9f9f9;
}

.landing {
  background-image: url("./assets/bg-1.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text-color {
  color: #2a89fd;
  /* color: #2a89fd; */
}

.text-color-2 {
  color: #161617;
}

.back-color {
  background-color: #2a89fd;
  /* background-color: #2a89fd; */
}

.back-color-2 {
  background-color: #161617;
}

.clip-path-custom {
  clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 5% 100%);
}
/* 
.clip-path-custom-2 {
  clip-path: polygon(70% 0%, 100% 0%, 100% 100%, 40% 100%);
}

.clip-path-custom-3 {
  clip-path: polygon(65% 0%, 100% 0%, 100% 100%, 40% 100%);
}

.clip-path-custom-4 {
  clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 30% 100%);
} */

.clip-path-custom-reversed {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 50% 100%);
}

.services-bg {
  background-image: url("./assets/bg-green.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text-font {
  font-family: "Poppins", sans-serif;
}

.text-color-3 {
  color: #717175;
}

/* .cursor-tail {
  border: 2px solid #59dce7;
  border-radius: 50%;
  position: fixed;
  padding: 10px;
  pointer-events: none;
  transition: transform 0.1s linear;
  z-index: 1000;
} */

.brand::before,
.brand::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #717175;
  margin: 0 20px;
  opacity: 0.3;
}

.brand-clip {
  /* clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 20% 100%); */
}

/* .about-bg {
  background-image: url("./assets/bg1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
} */

.contact-bg {
  background-image: url("./assets/contact-bg-2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

::placeholder,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

textarea {
  resize: none;
}

.footer {
  background-image: url("./assets/footer-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
