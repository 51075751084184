/* Hero Area  */
.hero-area-img {
    position: relative;
    right: -20%;
    animation: heroAreaImg 1s ease-in-out forwards;
}

@keyframes heroAreaImg {
    from {
        right: -20%;
    }

    to {
        right: 0%;
    }
}

/* Services  */

.service-box:hover{
    transition: 0.3s all;
    background: #2A88FC;
    cursor: pointer;
    box-shadow: 0 0 5px lightgray;
}
.service-box:hover .service-icon{
    transition: 0.3s all;
    fill: #59DCE7;
}
.service-box:hover .service-title{
    font-weight: bold;
}